import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Container,
  Box,
  Typography,
  InputAdornment,
  IconButton,
  CircularProgress, // Importando CircularProgress
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "./login.css";
import { useNavigate } from "react-router-dom";
import { registerUser } from "./api/auths";
import { toast } from "react-toastify";

function Register() {
  const [email, setEmail] = useState("");
  const [nome, setNome] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Estado de carregamento

  useEffect(() => {
    document.title = "Cadastre sua conta";
  }, []);

  const navigate = useNavigate();

  const navigateToLogin = () => {
    navigate("/");
  };

  const handleRegister = async () => {
    setIsLoading(true); // Inicia o carregamento
    try {
      const response = await registerUser(nome, email, password, confirmPassword);
      toast.success(response.message);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false); // Finaliza o carregamento
    }
  };

  // Função para alternar a visibilidade da senha
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Container maxWidth="sm">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <img src="/logo.png" alt="Logo" className="logo" />

          <Typography variant="h4" gutterBottom sx={{ color: "#b6b6c8" }}>
            Crie sua conta
          </Typography>

          <TextField
            label="Nome"
            variant="outlined"
            margin="normal"
            fullWidth
            value={nome}
            onChange={(e) => setNome(e.target.value)}
            InputLabelProps={{ style: { color: "#b6b6c8" } }}
            inputProps={{ style: { color: "#b6b6c8" } }}
            sx={{
              ".MuiOutlinedInput-notchedOutline": { borderColor: "#b6b6c8" },
            }}
            required
          />
          <TextField
            label="Email"
            variant="outlined"
            margin="normal"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            InputLabelProps={{ style: { color: "#b6b6c8" } }}
            inputProps={{ style: { color: "#b6b6c8" } }}
            sx={{
              ".MuiOutlinedInput-notchedOutline": { borderColor: "#b6b6c8" },
            }}
            required
          />
          <TextField
            label="Senha"
            type={showPassword ? "text" : "password"}
            variant="outlined"
            margin="normal"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputLabelProps={{ style: { color: "#b6b6c8" } }}
            inputProps={{ style: { color: "#b6b6c8" } }}
            sx={{
              ".MuiOutlinedInput-notchedOutline": { borderColor: "#b6b6c8" },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowPassword}
                    edge="end"
                    style={{ color: "#b6b6c8" }}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            required
          />
          <TextField
            label="Confirmação da senha"
            type={showConfirmPassword ? "text" : "password"}
            variant="outlined"
            margin="normal"
            fullWidth
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            InputLabelProps={{ style: { color: "#b6b6c8" } }}
            inputProps={{ style: { color: "#b6b6c8" } }}
            sx={{
              ".MuiOutlinedInput-notchedOutline": { borderColor: "#b6b6c8" },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowConfirmPassword}
                    edge="end"
                    style={{ color: "#b6b6c8" }}
                  >
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            required
          />

          {isLoading ? (
            <CircularProgress sx={{ marginTop: 2 }} /> // Exibe o indicador de carregamento
          ) : (
            <Button
              variant="outlined"
              color="primary"
              size="large"
              sx={{ marginTop: 2 }}
              fullWidth
              onClick={handleRegister}
            >
              Criar conta
            </Button>
          )}

          <Button
            variant="outlined"
            color="primary"
            startIcon={<ArrowBackIcon sx={{ color: "#1976d2" }} />}
            sx={{ marginTop: 2 }}
            onClick={navigateToLogin}
          >
            Faça seu login
          </Button>
        </Box>
      </Container>
    </Box>
  );
}

export default Register;
