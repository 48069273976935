//auths.js
import axios from 'axios';

const API_URL = "https://guilhermesystems.com.br/vivalem/api/";

export const authenticateAndGetToken = async (username, password) => {
    try {
        const bodyString = `email=${encodeURIComponent(username)}&senha=${encodeURIComponent(password)}`;

        const response = await axios.post(`${API_URL}gerarToken`, bodyString, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });

        if (response.status === 200 && response.data) {
            console.log('Dados da autenticação:', response.data);

            return {
                token: response.data.token,
                message: response.data.message,
                usuarioNome: response.data.usuarioNome,
                usuarioID: response.data.usuarioID,
                email: response.data.email,
                data_criacao: response.data.data_criacao
            };
        } else {
            throw new Error('Erro na autenticação. Tente novamente.');
        }
    } catch (error) {
        let errorMessage = '';

        if (error.response) {
            errorMessage = `${error.response.data.message}`;
            if (error.response.data.paymentLink) {
                errorMessage += ` [aqui](${error.response.data.paymentLink})`;
            }
        } else if (error.request) {
            errorMessage = 'Erro ao fazer a tentativa de login. Tente novamente.';
        } else {
            errorMessage = 'Estamos problemas com o servidor. Foi detectado uma possível instabilidade em nossos serviços.';
        }

        if (error.status === 524) {
            errorMessage = 'Foi detectado uma possível instabilidade em nossos servidores. Tente novamente.';
        }

        throw new Error(errorMessage);
    }
};

export const registerUser = async (nome, email, senha, confirmacaoSenha) => {
    try {
        const bodyString = `nome=${encodeURIComponent(nome)}&email=${encodeURIComponent(email)}&senha=${encodeURIComponent(senha)}&confirmacaoSenha=${encodeURIComponent(confirmacaoSenha)}`;
  
        const response = await axios.post(`${API_URL}usuario/cadastrarUsuario`, bodyString, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
  
        if (response.status === 201 && response.data) {
            return {
                message: response.data.message,
            };
        } else {
            throw new Error('Erro ao cadastrar usuário. Tente novamente.');            
        }
    } catch (error) {
        throw new Error(error.response.data.message);        
    }
};

export const confirmarEmail = async (codigo) => {
    try {
        const response = await axios.get(`${API_URL}usuario/confirmarEmail?codigo=${encodeURIComponent(codigo)}`);

        if (response.status === 200) {
            return {
                message: response.data.message,
            };
        } else {
            throw new Error('Erro ao confirmar email. Tente novamente.');            
        }
    } catch (error) {
        throw new Error(error.response.data.message);        
    }
};