// Recover.js
import React, { useEffect, useState } from "react";
import { TextField, Button, Container, Box, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "./login.css";
import { useNavigate } from "react-router-dom";

function Recover() {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  useEffect(() => {
    document.title = "Recupere sua senha";
  }, []);

  const navigate = useNavigate();

  const navigateToLogin = () => {
    navigate("/");
  };

  const navigateToRegister = () => {
    navigate("/cadastrar");
  };

  const handleRecover = () => {
    let hasError = false;

    if (!email) {
      setEmailError("O email é obrigatório.");
      hasError = true;
    } else {
      setEmailError("");
    }

    if (!hasError) {
      // Aqui você pode adicionar a lógica para fazer o login.
      console.log("Tentando fazer login...");
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Container maxWidth="sm">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <img src="/logo.png" alt="Logo" className="logo" />

          <Typography variant="h4" gutterBottom sx={{ color: "#b6b6c8" }}>
            Recuperar senha
          </Typography>

          <Typography variant="h6" gutterBottom sx={{ color: "white" }}>
            Informe seu email para receber instruções para recuperar sua senha
          </Typography>

          <TextField
            label="Email"
            variant="outlined"
            margin="normal"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={!!emailError}
            helperText={emailError}
            InputLabelProps={{ style: { color: "#b6b6c8" } }}
            inputProps={{ style: { color: "#b6b6c8" } }}
            sx={{
              ".MuiOutlinedInput-notchedOutline": { borderColor: "#b6b6c8" },
            }}
            required
          />
          <Button
            variant="outlined"
            color="primary"
            size="large"
            sx={{ marginTop: 1 }}
            fullWidth
            onClick={handleRecover}
          >
            Recuperar
          </Button>

          <Button
            variant="outlined"
            color="primary"
            startIcon={<ArrowBackIcon sx={{ color: "#1976d2" }} />}
            sx={{ marginTop: 2 }}
            onClick={navigateToLogin}
          >
            Faça seu login
          </Button>

          <Button
            variant="outlined"
            color="primary"
            startIcon={<ArrowBackIcon sx={{ color: "#1976d2" }} />}
            sx={{ marginTop: 2 }}
            onClick={navigateToRegister}
          >
            Crie sua conta
          </Button>
        </Box>
      </Container>
    </Box>
  );
}

export default Recover;
