// Login.js
import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Container,
  Box,
  Typography,
  Modal,
} from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";
import { authenticateAndGetToken, confirmarEmail } from "./api/auths";
import { toast } from "react-toastify";
import qrCodeImage from "./qrcodepix.png";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [open, setOpen] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const navigate = useNavigate();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // useEffect para capturar o código da URL e chamar a função de confirmação
  useEffect(() => {
    document.title = "Faça seu login";

    const params = new URLSearchParams(window.location.search);
    const codigo = params.get("codigo");

    const handleConfirmEmail = async () => {
      if (!codigo || isProcessing) return; // Impede a execução se o código não existir ou já estiver em processamento

      setIsProcessing(true); // Marca como em processamento para evitar reexecuções

      try {
        const data = await confirmarEmail(codigo);
        toast.success(data.message);

        // Remove o parâmetro da URL
        const url = new URL(window.location.href);
        url.searchParams.delete('codigo');
        window.history.replaceState({}, document.title, url.pathname);
      } catch (error) {
        console.log("ERRO Confirmação:", error);
        toast.error(error.message);
      } finally {
        setIsProcessing(false); // Reseta o estado após o término da operação
      }
    };

    console.log("TESTE AQUI: "+isProcessing);

    handleConfirmEmail(); // Executa a função de confirmação
  }, [isProcessing]);

  const handleLogin = async () => {
    try {
      const data = await authenticateAndGetToken(email, password);
      toast.success(data.message);
    } catch (error) {
      console.log("ERRO login:", error);
      toast.error(error.message);
    }
  };

  const navigateToRegister = () => {
    navigate("/cadastrar");
  };

  const navigateToRecover = () => {
    navigate("/recuperar");
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Container maxWidth="sm">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <img src="/logo.png" alt="Logo" className="logo" />

          <Typography variant="h4" gutterBottom sx={{ color: "#b6b6c8" }}>
            Faça seu login
          </Typography>

          <TextField
            label="Email"
            variant="outlined"
            margin="normal"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            InputLabelProps={{ style: { color: "#b6b6c8" } }}
            inputProps={{ style: { color: "#b6b6c8" } }}
            sx={{
              ".MuiOutlinedInput-notchedOutline": { borderColor: "#b6b6c8" },
            }}
            required
          />
          <TextField
            label="Senha"
            type="password"
            variant="outlined"
            margin="normal"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputLabelProps={{ style: { color: "#b6b6c8" } }}
            inputProps={{ style: { color: "#b6b6c8" } }}
            sx={{
              ".MuiOutlinedInput-notchedOutline": { borderColor: "#b6b6c8" },
            }}
            required
          />

          <Button
            variant="text"
            color="info"
            style={{ color: "#b6b6c8" }}
            onClick={navigateToRecover}
          >
            Recuperar senha
          </Button>

          <Button
            variant="outlined"
            color="primary"
            size="large"
            sx={{ marginTop: 1 }}
            fullWidth
            onClick={handleLogin}
          >
            Logar
          </Button>

          <Button
            variant="outlined"
            color="primary"
            endIcon={<ArrowForwardIcon sx={{ color: "#1976d2" }} />}
            sx={{ marginTop: 2 }}
            onClick={navigateToRegister}
          >
            Crie sua conta gratuita
          </Button>

          <Button
            variant="outlined"
            color="secondary"
            startIcon={<FavoriteIcon sx={{ color: "red" }} />}
            sx={{ marginTop: 2 }}
            onClick={handleOpen}
          >
            Doe para me ajudar
          </Button>

          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 300,
                bgcolor: "background.paper",
                border: "2px solid #000",
                boxShadow: 24,
                p: 4,
                textAlign: "center",
              }}
            >
              <Typography id="modal-title" variant="h6" component="h2">
                Doe para apoiar meu projeto!
              </Typography>
              <Typography id="modal-description" sx={{ mt: 2 }}>
                Este sistema é gratuito, mas há custos para mantê-lo no ar.
                Qualquer quantia é bem-vinda e me ajuda muito!
              </Typography>
              <Typography sx={{ mt: 2, fontWeight: "bold" }}>
                PIX: guimattosds@gmail.com
                <img
                  className="logo"
                  src={qrCodeImage}
                  alt="QR Code para doação via Pix"
                  style={{ width: "200px", marginTop: "10px" }}
                />
              </Typography>
              <Button
                variant="contained"
                color="primary"
                sx={{ mt: 2 }}
                onClick={handleClose}
              >
                Fechar
              </Button>
            </Box>
          </Modal>
        </Box>
      </Container>
    </Box>
  );
}

export default React.memo(Login);
